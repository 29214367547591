import React from "react";


// import Custom Components

import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import CartPage from "../components/cart";
import SEO from "../components/common/SEO";
function Cart() {

  return (
    <>
      <MainLayout>
        
      <SEO title="Jimenez Store Mr. Discount - Shopping Cart" />
        <div className="main">

          <h1 className="d-none">
          Jimenez Store Mr. Discount - Shopping Cart
          </h1>

          <div className="main">
            <PageHeader title="Shopping Cart" subTitle="Shop" />
            <Breadcrumb
              title="Shopping Cart"
              // parent1={["Shop", "shop/sidebar/list"]}
            />
            <CartPage />
          </div>
        </div>
      </MainLayout>
    </>
  );
}

export default Cart;
